<template>
  <div class="PICkingListBaleIDs pvhFactory">
    <basic-container>
      <div class="layTop">
      <div class="Title" style="margin-bottom:10px;">{{ $t('PickingListBaleIDs') }}</div>
      </div>
      <template>
          <el-row v-if="!isAdvancedSearch" class="topFromSearch">
             <el-col class="formDiv" :xs="24" :sm="21" :md="21" :lg="21" :xl="21" style="align-self: flex-end; ">
                  <el-input style="width:550px;" v-model="SeaCodeVal" :placeholder="$t('PLEASE ENTER THE KEYWORDS TO BE SEARCHED')"></el-input>
                  <el-badge :value="selectBadge" style="margin-right: 18px; margin-left: 18px; ">
                      <el-button plain icon="iconfont shaixuan2" size="mini" :title="$t('Display Filter')" @click="isAdvancedSearch=true;">{{$t('Filter')}}</el-button>
                  </el-badge>
                  <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search"> {{$t('Search')}}</el-button>
              </el-col>
          </el-row>
          <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="SeaCodeVal==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('KEYWORDS')}}:</span>
                      <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="SeaCodeVal"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="PickingNumber ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{ $t('Picking Number') }}:</span>
                      <el-input v-model="PickingNumber" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="BaleID ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{ $t('Bale ID') }}:</span>
                      <el-input v-model="BaleID" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="PickinaBeainTime ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{ $t('Pickina Beain Time') }}:</span>
                      <el-date-picker class="dataicon" v-model.trim="PickinaBeainTime"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon=""
                                      format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width:100%;">
                      </el-date-picker>
                  </el-col>
                  <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                      <span :style="PickingEndTime ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{ $t('Picking End Time') }}:</span>
                      <el-date-picker class="dataicon" v-model.trim="PickingEndTime"
                                      type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon=""
                                      format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width:100%;">
                      </el-date-picker>
                  </el-col>
              </el-col>
          </el-row>
          <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style=" text-align: right; align-self: flex-end;">
                  <el-button plain icon="iconfont shaixuan1" size="mini" :title="$t('Hidden Filter')" @click="isAdvancedSearch=false;getSelectBadge()">  {{$t('Hidden Filter')}} </el-button>
                  <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search">  {{$t('Search')}}</el-button>
              </el-col>
          </el-row>
          <div>
              <div style="margin-top: 10px;">
                  <avue-crud class="pvhTable"
                             v-loading="loading"
                             v-model="obj"
                             :option="tableOpt"
                             :data="tableData"
                             :row-style="rowStyle"
                             :page.sync="page"
                             @current-change="currentChange">

                      <template slot="menuRight" style="width: calc(100% - 80px);">
                          <el-row :gutter="20" class="topFromSearch">

                              <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                  <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                      {{ $t('ADD') }}
                                  </el-button>
                              </el-col>
                          </el-row>
                      </template>
                      <template slot-scope="{row,index,size,type}" slot="menu">
                          <el-button icon="iconfont icon-dingwei1" type="text" size="medium" :title="$t('Tracing')"
                                     style="margin-left:10px;" @click="SelectData(row)"></el-button>
                      </template>

                  </avue-crud>
              </div>
          </div>
      </template>
    </basic-container>
  </div>
</template>

<script>
import {GetCottonBales} from '@/api/maintain'
import {getDateYYYMMDD} from "@/api/purchaseOrd";

export default {
  name: "PickingListBaleIDs",
  data() {
      return {
      SeaCodeVal: '',
      selectBadge: null,
      isAdvancedSearch: false,
      loading: true,
      PickingNumber: '',
      BaleID: '',
      PickinaBeainTime: '',
      PickingEndTime: '',
      obj: {},
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],

      beginTimeVal: '',
      sourceVal: '',
      endTimeVal: '',
      qrCodeIdVal: ''
    }
  },
  created() {
    this.list('', '', '', '');
  },
        methods: {
            getSelectBadge() {
                this.selectBadge = 0;
                if (this.PickingNumber != "") {
                    ++this.selectBadge;
                };
                if (this.BaleID != "") {
                    ++this.selectBadge;
                };
                if (this.PickinaBeainTime != "") {
                    ++this.selectBadge;
                };
                if (this.PickingEndTime != "") {
                    ++this.selectBadge;
                };
                if (this.selectBadge == 0) {
                    this.selectBadge = null;
                }
            },
            list(SeaCode, pickNo, baleId, beginDate, endDate) {
        GetCottonBales({
        seaCode: SeaCode,
        pickNo: pickNo,
        baleId: baleId,
        beginDate: beginDate ? getDateYYYMMDD(beginDate) : '',
        endDate: endDate ? getDateYYYMMDD(endDate) : '',
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
      }).then((response) => {
        this.loading = false;
        console.log(response)
        this.tableData = response.data.cottonBales;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
          obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        } else {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        }

      }
    },
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else if (obj.currentTarget === null) {
        if (this.userId == null || this.userId.length === 0) {
          document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    currentChange(val) {
      this.page.currentPage = val
        this.list('','', '', '', '')
      // this.getOperateLogList();
    },
    search() {
      /*
      *  PickingNumber: '',
      BaleID: '',
      PickinaBeainTime: '',
      PickingEndTime: '',*/
        this.list(this.SeaCodeVal,this.PickingNumber, this.BaleID, this.PickinaBeainTime, this.PickingEndTime)
    },
    SelectData(row) {
      console.log(row);
      this.$router.push({
        name: 'CottonBaleDetails',
        query:{
          subjectId: row.subjectId,
          corporationId: row.corporationId,
          pickingNo: row.pickingNo
        }
      })
    },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
            label: this.$t('PICKING NUMBER'),
            prop: 'pickingNo',
          },
          {
            label: this.$t('PICKING DATE'),
            prop: 'pickingDate',
          },
          {
            label: this.$t('YARN SUPPLIER'),
            prop: 'supplier',
          },{
            label: this.$t('BALE COUNT'),
            prop: 'baleCount',
          },{
            label: this.$t('PICKING QUANTITY'),
            prop: 'pickingQuantity',
          },
          /*{
            label: this.$t('STATE'),
            prop: 'status',
            type: 'select',
            dicData: this.YesNoData,
          }*/
        ]
      }
    },
  }
}
</script>

<style scoped>

</style>
